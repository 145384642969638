import RoutesJson, { RoutesLayoutHandler } from 'config/routes-items';
import { Outlet, Route } from 'react-router-dom';
import { RequireAuth, RequireNoAuth } from './app-helper';

const Router = () => {
	const RoutesJSON = RoutesJson();
	return RoutesJSON.map((item, index) => {
		if (item.authType === 'guardless') {
			return (
				<Route
					path={item.route}
					element={
						<RoutesLayoutHandler layout={item.layout}>
							<item.component />
						</RoutesLayoutHandler>
					}
					key={'route' + index}
				></Route>
			);
		} else if (item.authType === 'auth') {
			return (
				<Route
					path={item.route}
					element={
						<RequireAuth>
							<RoutesLayoutHandler layout={item.layout}>
								<item.component />
							</RoutesLayoutHandler>
						</RequireAuth>
					}
					key={'route' + index}
				/>
			);
		} else if (item.authType === 'no-auth') {
			return (
				<Route
					path={item.route}
					element={
						<RequireNoAuth>
							<RoutesLayoutHandler layout={item.layout}>
								<item.component />
							</RoutesLayoutHandler>
						</RequireNoAuth>
					}
					key={'route' + index}
				/>
			);
		} else {
			return <Outlet />;
		}
	});
};

export default Router;
