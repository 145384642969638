import styled from '@emotion/styled';

export const MainField = styled.div({
	display: 'flex',
	width: '100%',
	height: 'calc(100vh - 64px)',

	'@media (max-width: 1218px)': {
		flexDirection: 'column',
		marginBottom: '0px',
	},
});

export const ContentField = styled.div(props => ({
	width: '100%',
	background: props.theme.mode === 'light' ? 'var(--color-primary-background-light-base)' : 'var(--color-secondary-background-dark-base)',
	padding: ' 16px',
	overflowX: 'hidden',
	paddingBottom: '40px',

	'@media (min-width: 1218px)': {
		padding: '32px 40px',
		paddingBottom: '80px',
	},
}));
