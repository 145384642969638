import requestHandler from 'utils/requestHandler';
import { IGeoLocationResponse } from './edge.dto';

const BASE_URL = '/edge';

export const GETGeoLocation = async () => {
	return requestHandler
		.call<IGeoLocationResponse>({ url: `https://api.coinlocally.com/${BASE_URL}/geolocation`, method: 'get' })
		.then(res => res);
};
