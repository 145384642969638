export const RouteTheme: { route: string; theme: 'dark' | 'light' }[] = [
	{
		route: '/landing/xmas-cashback',
		theme: 'light',
	},
	{
		route: '/landing/3commas',
		theme: 'light',
	},
	{
		route: '/landing/ai-multilanguage',
		theme: 'light',
	},
	{
		route: '/campaign/trade-win',
		theme: 'dark',
	},
	{
		route: '/black-friday',
		theme: 'dark',
	},
	{
		route: '/landing/auto-share',
		theme: 'light',
	},
	{
		route: '/event-futures',
		theme: 'dark',
	},
	{
		route: '/landing/forex-bonus',
		theme: 'dark',
	},
	{
		route: '/landing/radins-forex-bonus',
		theme: 'dark',
	},
	{
		route: '/landing/forex-deposit-bonus',
		theme: 'dark',
	},
	{
		route: '/campaign/halloween',
		theme: 'dark',
	},
];
