import ThemeBanner from './theme.home-page-banner';
import themePalette from './theme.palettes';

export const getDesignTokensOld = (mode: 'dark' | 'light', dir: 'rtl' | 'ltr') => ({
	mode,
	dir,
	palette: {
		mainColors: {
			dark_accent: '#d7ad02',
			accent: '#FCD535',
			accent_hover: '#FFE16B',
			accent_gradiant: 'linear-gradient(180deg, #F8D12F 0%, #F0B90B 100%)',
			accent_gradiant_hover: 'linear-gradient(180deg, #fae48a 0%, #ffcd30 100%)',
			red: '#FF7070',
			green: '#2EBD85',
		},
		mode,
		...themePalette[mode],
	},
	size: {
		radius: {
			sharp: '4px',
			halfRounded: '8px',
			rounded: '600px',
		},
	},
	...(mode === 'light'
		? {
				colors: {
					background: {
						primary: '#FFFFFF',
						secondary: '#F7F7F7',
						tertiary: '#F7F7F7',
						quaternary: '#FFFFFF',
						elevated: '#F7F7F7',
						green: '#D7F5E3',
						red: '#FFE3E3',
						yellow: '#FCD535',
					},
					text: {
						primary: '#000000',
						secondary: '#6D6D70',
						tertiary: '#BEBEC0',
						quaternary: '#D4D4D5',
						green: '#009B79',
						red: '#E51717',
						yellow: '#FCD535',
					},
					border: {
						primary: '#E1E3E5',
					},
				},
		  }
		: {
				colors: {
					background: {
						primary: '#000000',
						secondary: '#18181A',
						tertiary: '#1C1C1E',
						quaternary: '#2C2C2E',
						elevated: '#272729',
						green: '#1F3C35',
						red: '#4C2020',
						yellow: '#FCD535',
					},
					text: {
						primary: '#FFFFFF',
						secondary: '#939393',
						tertiary: '#464649',
						quaternary: '#2A2A2C',
						green: '#06D6A9',
						red: '#FF6961',
						yellow: '#FCD535',
					},
					border: {
						primary: '#38383A',
					},
				},
		  }),
	...ThemeBanner,
});
