import packageJSON from '../../../package.json';

const envResolver = {
	COIN_LOGO_BASE_URL: process.env.REACT_APP_COIN_LOGO_BASE_URL ?? 'https://clycpublic.ams3.cdn.digitaloceanspaces.com/assets/',
	BOT_API_BASE_URL: process.env.REACT_APP_BOT_API_BASE_URL ?? 'https://api-bot.coinlocally.com',
	API_BASE_URL: process.env.REACT_APP_API_BASE_URL ?? 'https://api.coinlocally.com/',
	GOOGLE_AUTH_CLIENT_ID:
		process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID ?? '1097530902697-r3rmoku9l37gqrn6l4i6u5n9ko4evooa.apps.googleusercontent.com',
	FOREX_TRADING_TERMINAL_URL: 'https://web.coinlocally.forex/terminal',
	VERSION: packageJSON.version,
	AFFILIATE_TELEGRAM_BOT_ID: process.env.REACT_APP_AFFILIATE_AUTOSHARE_BOT_ID ?? '@coinlocallyShareBot',
};

export { envResolver };
