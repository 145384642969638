import { useMatchMedia } from '@coinlocally/cly-core/hooks';
import { AffiliateAsideConfig, IbAsideConfig } from 'config/dashboard';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import AffiliateCustomerSupport from './affiliate-support';
import { SidebarContainer } from './style/dashboard-aside.style';
import { TAsideConfig, TAsideTypes } from './types';

const Sidebar = ({ asideType }: { asideType: TAsideTypes }) => {
	const [activeIndex, setActiveIndex] = useState(0);
	const asideRef = useRef<HTMLDivElement>(null);
	const linkRefs = useRef<(HTMLAnchorElement | null)[]>([]);
	const { i18n, t } = useTranslation('aside');
	const language = i18n.language;
	const isTablet = useMatchMedia('(max-width: 1218px)');
	const ib_dashboard = IbAsideConfig();
	const affiliate_dashboard = AffiliateAsideConfig();

	const asideConfig: TAsideConfig = {
		IBDashboard: {
			title: t('ib-dashboard'),
			links: ib_dashboard,
		},
		AffiliateDashboard: {
			title: t('affiliate-dashboard'),
			links: affiliate_dashboard,
			bottomSection: {
				height: '350px',
				component: <AffiliateCustomerSupport />,
			},
		},
	};

	useEffect(() => {
		// mobile horizontal scroll effect
		if (isTablet && asideRef.current && linkRefs.current[activeIndex]) {
			const containerWidth = asideRef.current.offsetWidth;
			const activeItemWidth = linkRefs.current[activeIndex]?.offsetWidth;
			const activeItemOffset = linkRefs.current[activeIndex]?.offsetLeft;
			const scrollLeft = activeItemOffset! - (containerWidth - activeItemWidth!) / 2;
			asideRef.current.scrollTo({
				left: scrollLeft,
				behavior: 'smooth',
			});
		}
	}, [activeIndex]);

	return (
		<SidebarContainer className='dashboard_sidebar'>
			<div className=' aside_title_mobile_container'>
				<h3>{asideConfig[asideType].title}</h3>
			</div>
			<div
				ref={asideRef}
				className={isTablet ? 'horizontal_view' : 'vertical_view'}
				style={
					!isTablet
						? {
								height: asideConfig[asideType].bottomSection?.height
									? `calc(100vh - ${asideConfig[asideType].bottomSection?.height})`
									: 'calc(100vh - 50px)',
						  }
						: {}
				}
			>
				<div className='aside_title_container'>
					<h3>{asideConfig[asideType].title}</h3>
				</div>
				{asideConfig[asideType].links.map((item, index) => (
					<NavLink
						key={`sidebar_config_${index}`}
						to={`/${language}/${item.link}`}
						end={true}
						className={isTablet ? 'horizontal_item' : 'vertical_item'}
						onClick={() => setActiveIndex(index)}
						ref={ref => (linkRefs.current[index] = ref)}
					>
						{!isTablet && item.icon}
						{item.title}
					</NavLink>
				))}
			</div>
			{}
			{!isTablet && asideConfig[asideType].bottomSection?.height ? asideConfig[asideType].bottomSection?.component : null}
		</SidebarContainer>
	);
};

export default Sidebar;
