import { MessageOutline, TelegramOutline } from '@coinlocally/cly-core/icons';
import { useAppSelector } from 'hooks/redux-helper';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SupportDark, SupportLight } from './img/affiliate-dashboard';
import { AffiliateCustomerSupportContainer } from './style/affiliate-customer-support.style';

interface AffiliateCustomerSupportProps {}

const AffiliateCustomerSupport: FC<AffiliateCustomerSupportProps> = () => {
	const { t } = useTranslation('affiliate_dashboard');
	const theme = useAppSelector(state => state.ThemeProvider.theme);
	return (
		<AffiliateCustomerSupportContainer>
			{theme === 'light' ? <SupportLight className='support_logo' /> : <SupportDark className='support_logo' />}
			<div className='content'>
				<p className='title'>{t('your-exclusive-customer-support')}</p>
				<div className='bordered'></div>
				<a className='social_container' href={`https://t.me/coinlocallyaffiliate`} target='_blank' rel='noreferrer'>
					<span>
						<span className='icon_box'>
							<TelegramOutline />
						</span>
						<p>@coinlocallyaffiliate</p>
					</span>
				</a>
				<div className='social_container email'>
					<span>
						<span className='icon_box'>
							<MessageOutline />
						</span>
						<p>affiliates@coinlocally.com</p>
					</span>
				</div>
			</div>
		</AffiliateCustomerSupportContainer>
	);
};

export default AffiliateCustomerSupport;
