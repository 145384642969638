import styled from '@emotion/styled';

export const Aside = styled.aside(props => ({
	width: '240px',
	backgroundColor:
		props.theme.mode === 'light' ? 'var(--color-secondary-background-light-base)' : 'var(--color-tertiary-background-dark-base)',
	minHeight: 'calc(100vh - 64px)',
	position: 'sticky',
	top: '64px',
	overflow: 'auto',
	paddingBottom: '60px',

	'& .active': {
		color: props.theme.palette.background.dark_accent,
		backgroundColor: props.theme.palette.background.profileAsideActive,
		borderLeft: `4px solid ${props.theme.palette.mainColors.dark_accent} `,
	},

	a: {
		padding: '12px 22px',
		color: props.theme.palette.textColor.default,
		fontSize: '0.9rem',
		display: 'flex',
		alignItems: 'center',
		position: 'relative',
		transition: 'all linear 0.1s',

		span: {
			position: 'absolute',
			right: '14px',
			backgroundColor: props.theme.palette.mainColors.red,
			color: 'white',
			paddingTop: '2px',

			'&.quest_reward': {
				right: '24px',
			},
		},

		'&:hover': {
			color: props.theme.palette.mainColors.dark_accent,

			i: {
				color: props.theme.palette.mainColors.dark_accent,
			},
		},

		i: {
			marginRight: '10px',
			fontSize: '1.2rem',
			position: 'relative',
			bottom: '2px',
		},
	},

	'& .disabledPartner': {
		pointerEvents: 'none',
		opacity: '0.4',
	},

	'@media (max-width: 1082px)': {
		display: 'none',
	},
}));

export const MenuButton = styled.div(props => ({
	display: 'block',
	color: 'white',
	width: '100%',
	backgroundColor: 'transparent',
	textAlign: 'left',
	cursor: 'pointer',
	// marginBottom: '20px',

	div: {
		padding: '10px 20px',
		fontSize: '0.9rem',
		color: props.theme.palette.blackAndWhite,

		i: {
			color: props.theme.palette.blackAndWhite,
		},

		'&:hover': {
			color: props.theme.palette.mainColors.dark_accent,

			i: {
				color: props.theme.palette.mainColors.dark_accent,
			},
		},
	},

	i: {
		marginRight: '15px',
		fontSize: '1.2rem',
	},

	'& .angle': {
		position: 'absolute',
		right: '0',
		transition: 'all ease 0.2s',
	},

	'&.active': {
		backgroundColor: '#32353e',
	},

	'&.active_menu': {
		ul: {
			height: `calc(${props.count} * 43px)`,
		},

		'& .angle': {
			transform: 'rotate(90deg)',
		},
	},

	ul: {
		overflow: 'hidden',
		height: '0',
		transition: 'all ease 0.2s',

		a: {
			color: props.theme.palette.textColor.default,
			paddingLeft: '40px',
			fontSize: '0.8rem',
		},

		i: {
			fontSize: '0.6rem !important',
		},
	},
}));
