import { createSlice } from '@reduxjs/toolkit';
import { IPromotion } from 'api/coinlocally/v1/promotions/promotions.dto';
import { IHomePage } from 'state-manager/types';

const initialState: IHomePage = {
	FuturesPairs: [],
	SpotPairs: [],
	isMobile: false,
	isMobileAuth: false,
	balanceVisible: localStorage.getItem('balanceVisible') ? (localStorage.getItem('balanceVisible') === 'true' ? true : false) : false,
	promotion: localStorage.getItem('promotion') ? JSON.parse(localStorage.getItem('promotion')!) : [],
};

export const Home = createSlice({
	name: 'Home',
	initialState: initialState,
	reducers: {
		changeFuturesPairs: (state, action) => {
			if (action.payload.type === 'init') {
				let pairs = action.payload.data.map((item: any) => {
					return {
						baseCoin: item.baseCoin,
						quoteCoin: item.quoteCoin,
						symbol: item.symbol,
						createdAt: item.launchTime,
						lastPrice: item.lastPrice,
						price24hPercentage: item.price24hPercentage,
						turnover24h: item.turnover24h,
					};
				});
				state.FuturesPairs = pairs;
			} else {
				let data = action.payload.data;
				for (let index = 0; index < state.FuturesPairs.length; index++) {
					let newData = data.find((item: any) => item.symbol === state.FuturesPairs[index].symbol);
					if (newData) {
						state.FuturesPairs[index].lastPrice = newData.lastPrice;
						state.FuturesPairs[index].turnover24h = newData.turnover24h;
						state.FuturesPairs[index].price24hPercentage = newData.price24hPcnt;
					}
				}
				state.FuturesPairs = state.FuturesPairs.filter(item => data.map((d: any) => d.symbol).includes(item.symbol));
			}
		},

		changeSpotPairs: (state, action) => {
			if (action.payload.type === 'init') {
				let pairs = action.payload.data.map((item: any) => {
					return {
						baseCoin: item.baseCoin,
						quoteCoin: item.quoteCoin,
						symbol: item.symbol,
						createdAt: item.createdAt,
						lastPrice: item.lastPrice,
						price24hPercentage: item.price24hPercentage,
						turnover24h: item.turnover24h,
					};
				});
				state.SpotPairs = pairs;
			} else {
				let data = action.payload.data;
				for (let index = 0; index < state.SpotPairs.length; index++) {
					let newData = data.find((item: any) => item.symbol === state.SpotPairs[index].symbol);
					if (newData) {
						state.SpotPairs[index].lastPrice = newData.lastPrice;
						state.SpotPairs[index].turnover24h = newData.turnover24h;
						state.SpotPairs[index].price24hPercentage = newData.price24hPcnt;
					}
				}
				state.SpotPairs = state.SpotPairs.filter(item => data.map((d: any) => d.symbol).includes(item.symbol));
			}
		},

		changeIsMobileStatus: (state, action) => {
			state.isMobile = action.payload;
		},
		changeMobileAuthStatus: (state, action) => {
			state.isMobileAuth = action.payload;
		},

		changeBalanceVisibilityStatus: (state, action) => {
			state.balanceVisible = action.payload;
			localStorage.setItem('balanceVisible', action.payload ? 'true' : 'false');
		},

		changePromotion: (
			state,
			action: {
				payload: { type: 'add' | 'update'; data?: IPromotion[]; update?: { id: number; lastVisited: number; shownCount: number } };
			},
		) => {
			let promotions = state.promotion;
			if (action.payload.type === 'add') {
				for (const obj of action.payload.data!) {
					if (!promotions.find(item => item.id === obj.id)) {
						promotions.push({
							...obj,
							lastVisited: 0,
							shownCount: 0,
						});
					}
				}
			} else {
				let findPromotion = promotions.findIndex(item => item.id === action.payload.update!.id);
				if (findPromotion !== -1) {
					promotions[findPromotion].lastVisited = action.payload.update!.lastVisited;
					promotions[findPromotion].shownCount = action.payload.update!.shownCount;
				}
			}

			localStorage.setItem('promotion', JSON.stringify(promotions));
			state.promotion = promotions;
		},
	},
});

export const {
	changeFuturesPairs,
	changeSpotPairs,
	changeIsMobileStatus,
	changeBalanceVisibilityStatus,
	changePromotion,
	changeMobileAuthStatus,
} = Home.actions;
export default Home.reducer;
