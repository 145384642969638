import { lazy } from 'react';

// layout
import Layout from 'component/layout/layout';
import DashboardLayout from 'shared/components/layout/dashboard-layout';
import NewDashboardLayout from 'shared/components/layout/new-dashboard-layout';

// components
const Login = lazy(() => import('pages/auth/login'));
const Register = lazy(() => import('pages/auth/register'));
const ForgetPassword = lazy(() => import('pages/auth/forget-password'));

const Affiliate = lazy(() => import('pages/affiliate/landing'));
const UserAffiliate = lazy(() => import('pages/affiliate/user'));
const AffiliateForm = lazy(() => import('pages/affiliate-form'));
const AboutUs = lazy(() => import('route/other/about-us'));
const Home = lazy(() => import('pages/home'));
const Terms = lazy(() => import('route/other/term-of-us'));
const Privacy = lazy(() => import('route/other/privacy'));
const DemoLanding = lazy(() => import('route/landing/demo'));
const Market = lazy(() => import('route/market/market'));
const Community = lazy(() => import('route/other/commiunity'));
const ICO = lazy(() => import('route/other/ICO'));
const Fees = lazy(() => import('pages/fees'));

const Referral = lazy(() => import('pages/referral/main-page'));
const ReferralSetting = lazy(() => import('pages/referral/referral-setting'));
const RefereesList = lazy(() => import('pages/referral/referees'));

const Halving = lazy(() => import('pages/halving'));

// affiliate dashboard
const AffiliateDashboard = lazy(() => import('pages/affiliate-dashboard/main-page'));
const AffiliateReferral = lazy(() => import('pages/affiliate-dashboard/referral'));
const AffiliateCampaignLinks = lazy(() => import('pages/affiliate-dashboard/campaign-links'));
const AffiliateCampaignsPerformance = lazy(() => import('pages/affiliate-dashboard/campaigns-performance'));
const AffiliateAutoShare = lazy(() => import('pages/affiliate-dashboard/auto-share'));
const AffiliateEarningsHistory = lazy(() => import('pages/affiliate-dashboard/earnings-history'));
const RefereesLeaderboard = lazy(() => import('pages/affiliate-dashboard/referees-leaderboard'));
const ReferralDetail = lazy(() => import('pages/affiliate-dashboard/referral-detail'));

const IBDashboard = lazy(() => import('pages/ib-dashboard/main-page'));
const IBLinks = lazy(() => import('pages/ib-dashboard/links'));
const IBAcquisitions = lazy(() => import('pages/ib-dashboard/acquisitions'));
const IBClientsList = lazy(() => import('pages/ib-dashboard/client-list/main-page'));
// const IBClientsListDetail = lazy(() => import('pages/ib-dashboard/client-list/client-detail'));
const IBAccounts = lazy(() => import('pages/ib-dashboard/accounts'));
const IBRewards = lazy(() => import('pages/ib-dashboard/rewards'));
const IBTransactions = lazy(() => import('pages/ib-dashboard/transactions'));

const Signal = lazy(() => import('pages/signal'));

const TradingRules = lazy(() => import('route/other/trading-rules'));
const SurveyLanding = lazy(() => import('route/landing/survey'));
const Announcement = lazy(() => import('route/other/announcement/announcement'));
const AnnouncementArticle = lazy(() => import('route/other/announcement/announcement-article'));
const Invoice = lazy(() => import('route/invoice/invoice'));
const AnnouncementCategory = lazy(() => import('route/other/announcement/category'));
const Withdraw = lazy(() => import('route/withdraw/withdraw'));
const Deposit = lazy(() => import('route/deposit/deposit'));
const MercuryoBuyCrypto = lazy(() => import('route/buy-crypto/mercuryo'));
const Partnership = lazy(() => import('route/partnership/partnership'));
const PartnershipAppliationForm = lazy(() => import('route/partnership/application-form'));
const ViewProfile = lazy(() => import('route/profile/profile/view'));
const DownloadLanding = lazy(() => import('route/download/download-landing'));
const APIKEY = lazy(() => import('route/landing/apikey'));
const TestFlightLanding = lazy(() => import('route/landing/test-flight'));
const RewardHub = lazy(() => import('route/rewards/reward-hub'));
const TaskCenter = lazy(() => import('route/task-center/TaskCenter'));
const VipPortal = lazy(() => import('route/vip-portal/vip-portal'));
const AiMultiLanguage = lazy(() => import('route/ai-multi-language/ai-multilanguage'));
const Tournament = lazy(() => import('pages/tournament'));
const TopTraders = lazy(() => import('pages/top-traders'));
const ForexTerminal = lazy(() => import('pages/forex-terminal'));

const TaskCenterRewards = lazy(() => import('pages/task-center-rewards'));
const Saving = lazy(() => import('pages/saving'));
const insurance = lazy(() => import('pages/insurance'));
const FundingRateHistory = lazy(() => import('pages/funding-rate-history'));

//landings
const CommasLanding = lazy(() => import('pages/landing/3commas'));
const XmasLanding = lazy(() => import('pages/landing/xmas-landing'));
const BlackFriday = lazy(() => import('pages/landing/black-friday'));
const ForexDepositBonusLanding = lazy(() => import('pages/landing/forex-deposit-bonus'));
const ForexBonusLanding = lazy(() => import('pages/landing/forex-bonus'));
const RadinsForexBonusLanding = lazy(() => import('pages/landing/forex-bonus-radin'));
const OtisForexBonusLanding = lazy(() => import('pages/landing/forex-bonus-otis'));
const TopTrendForexBonusLanding = lazy(() => import('pages/landing/forex-bonus-toptrend'));

// dashboard
const DashboardProfile = lazy(() => import('pages/dashboard/profile'));
const DashboardSecurity = lazy(() => import('pages/dashboard/security'));
const APIKey = lazy(() => import('pages/dashboard/api-key'));
const ManageApiKey = lazy(() => import('pages/dashboard/api-key/manage'));
const DashboardSecurityGoogleAuth = lazy(() => import('pages/dashboard/security/google-auth'));
const DashboardSecurityAntiPhishing = lazy(() => import('pages/dashboard/security/anti-phishing'));
const DashboardSecurityDeleteAccount = lazy(() => import('pages/dashboard/security/delete-account'));
const DashboardSecurityFreezeAccount = lazy(() => import('pages/dashboard/security/freeze-account'));
const DashboardSecurityAccountActivities = lazy(() => import('pages/dashboard/security/account-activities'));
const DashboardAPIkeyDoc = lazy(() => import('pages/dashboard/api-key/document'));

const ForexAccount = lazy(() => import('pages/dashboard/forex'));
const ForexHistory = lazy(() => import('pages/dashboard/forex/history'));

const TradingBot = lazy(() => import('pages/trading-bot'));
// TODO: this will be used when backend become ready
// const DashboardSecurityDeviceManagement = lazy(() => import('pages/dashboard/security/device-management'));

const MarginTradeData = lazy(() => import('pages/margin-trade-data'));

// campaigns
const NewComersCampaign = lazy(() => import('pages/campaign/new-comers'));
const TradeLotteryCampaign = lazy(() => import('pages/campaign/trade-win'));
const HalloweenCampaign = lazy(() => import('pages/campaign/halloween'));
const AutoShare = lazy(() => import('pages/landing/auto-share'));

// future events
// const FutureEvents = lazy(() => import('pages/future-events'));
const EventsCalendar = lazy(() => import('pages/event-calendar'));

// SUBLayout profile
export default function RoutesJson() {
	return [
		{
			route: '',
			component: Home,
			authType: 'guardless',
			layout: {
				type: 'normal',
				props: {
					footerDisplay: true,
				},
			},
			sub: null,
		},
		{
			route: 'about-us',
			component: AboutUs,
			authType: 'guardless',
			layout: {
				type: 'normal',
				props: {
					footerDisplay: true,
				},
			},
			sub: null,
		},
		{
			route: 'Terms',
			component: Terms,
			authType: 'guardless',
			layout: {
				type: 'normal',
				props: {
					footerDisplay: true,
				},
			},
			sub: null,
		},
		{
			route: 'policy',
			component: Privacy,
			authType: 'guardless',
			layout: {
				type: 'normal',
				props: {
					footerDisplay: true,
				},
			},
			sub: null,
		},
		{
			route: 'landing/demo',
			component: DemoLanding,
			authType: 'guardless',
			layout: {
				type: 'normal',
				props: {
					footerDisplay: true,
				},
			},
			sub: null,
		},
		{
			route: 'landing/apikey',
			component: APIKEY,
			authType: 'guardless',
			layout: {
				type: 'normal',
				props: {
					footerDisplay: true,
				},
			},
			sub: null,
		},
		{
			route: 'market',
			component: Market,
			authType: 'guardless',
			layout: {
				type: 'normal',
				props: {
					footerDisplay: true,
				},
			},
			sub: null,
		},
		{
			route: 'landing/survey',
			component: SurveyLanding,
			authType: 'guardless',
			layout: {
				type: 'normal',
				props: {
					footerDisplay: true,
				},
			},
			sub: null,
		},
		{
			route: 'landing/auto-share',
			component: AutoShare,
			authType: 'guardless',
			layout: {
				type: 'normal',
				props: {
					footerDisplay: true,
				},
			},
			sub: null,
		},
		{
			route: 'community',
			component: Community,
			authType: 'guardless',
			layout: {
				type: 'normal',
				props: {
					footerDisplay: true,
				},
			},
			sub: null,
		},
		{
			route: 'CLYC',
			component: ICO,
			authType: 'guardless',
			layout: {
				type: 'normal',
				props: {
					footerDisplay: true,
				},
			},
			sub: null,
		},
		{
			route: 'fees',
			component: Fees,
			authType: 'guardless',
			layout: {
				type: 'normal',
				props: {
					footerDisplay: true,
				},
			},
			sub: null,
		},
		{
			route: 'rules',
			component: TradingRules,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'affiliate/:user',
			component: UserAffiliate,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: 'affiliate',
			component: Affiliate,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'affiliate-form',
			component: AffiliateForm,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: 'invoice/:id',
			component: Invoice,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'announcement-article/:articleId',
			component: AnnouncementArticle,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'announcement',
			component: Announcement,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},

		{
			route: 'announcement/category',
			component: AnnouncementCategory,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'Partnership',
			component: Partnership,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'leaderboard',
			component: TopTraders,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'profile/:username',
			component: ViewProfile,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'Partnership/application',
			component: PartnershipAppliationForm,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'withdraw',
			component: Withdraw,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'deposit',
			component: Deposit,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'login',
			component: Login,
			authType: 'no-auth',
			layout: { type: 'normal', props: { footerDisplay: false, navbarType: 'MINIMAL' } },
			sub: null,
		},
		{
			route: 'register',
			component: Register,
			authType: 'no-auth',
			layout: { type: 'normal', props: { footerDisplay: false, navbarType: 'MINIMAL' } },
			sub: null,
		},
		{
			route: 'forget-password',
			component: ForgetPassword,
			authType: 'no-auth',
			layout: { type: 'normal', props: { footerDisplay: false, navbarType: 'MINIMAL' } },
			sub: null,
		},
		{
			route: 'buy-crypto/mercuryo',
			component: MercuryoBuyCrypto,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'download',
			component: DownloadLanding,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},

		{
			route: 'landing/3commas',
			component: CommasLanding,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'landing/testflight',
			component: TestFlightLanding,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'landing/forex-deposit-bonus/:forexId',
			component: ForexDepositBonusLanding,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: 'landing/forex-bonus',
			component: ForexBonusLanding,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'landing/radins-forex-bonus',
			component: RadinsForexBonusLanding,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'landing/otis-forex-bonus',
			component: OtisForexBonusLanding,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'landing/toptrend-forex-bonus',
			component: TopTrendForexBonusLanding,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'reward-hub',
			component: RewardHub,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'task-center',
			component: TaskCenter,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'vip-portal',
			component: VipPortal,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'landing/ai-multilanguage',
			component: AiMultiLanguage,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'landing/xmas-cashback',
			component: XmasLanding,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'black-friday',
			component: BlackFriday,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'tournament',
			component: Tournament,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'task-center/rewards',
			component: TaskCenterRewards,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'saving',
			component: Saving,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'funding-rate-history',
			component: FundingRateHistory,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'affiliate-dashboard',
			component: AffiliateDashboard,
			authType: 'auth',
			layout: { type: 'dashboard', props: { footerDisplay: false, asideType: 'AffiliateDashboard', padding: '0' } },
			sub: null,
		},
		{
			route: 'affiliate-dashboard/referral',
			component: AffiliateReferral,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'referral/detail/:id',
			component: ReferralDetail,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: 'affiliate-dashboard/campaign-links',
			component: AffiliateCampaignLinks,
			authType: 'auth',
			layout: { type: 'dashboard', props: { footerDisplay: false, asideType: 'AffiliateDashboard', padding: '0' } },
			sub: null,
		},
		{
			route: 'affiliate-dashboard/campaigns-performance',
			component: AffiliateCampaignsPerformance,
			authType: 'auth',
			layout: { type: 'dashboard', props: { footerDisplay: false, asideType: 'AffiliateDashboard', padding: '0' } },
			sub: null,
		},
		{
			route: 'affiliate-dashboard/auto-share',
			component: AffiliateAutoShare,
			authType: 'auth',
			layout: { type: 'dashboard', props: { footerDisplay: false, asideType: 'AffiliateDashboard', padding: '0' } },
			sub: null,
		},
		{
			route: 'affiliate-dashboard/earnings-history',
			component: AffiliateEarningsHistory,
			authType: 'auth',
			layout: { type: 'dashboard', props: { footerDisplay: false, asideType: 'AffiliateDashboard', padding: '0' } },
			sub: null,
		},
		{
			route: 'affiliate-dashboard/referees-leaderboard',
			component: RefereesLeaderboard,
			authType: 'auth',
			layout: { type: 'dashboard', props: { footerDisplay: false, asideType: 'AffiliateDashboard', padding: '0' } },
			sub: null,
		},
		{
			route: 'ib-dashboard',
			component: IBDashboard,
			authType: 'auth',
			layout: { type: 'dashboard', props: { footerDisplay: true, asideType: 'IBDashboard' } },
			sub: null,
		},
		{
			route: 'ib-dashboard/links',
			component: IBLinks,
			authType: 'auth',
			layout: { type: 'dashboard', props: { footerDisplay: true, asideType: 'IBDashboard' } },
			sub: null,
		},
		{
			route: 'ib-dashboard/acquisitions',
			component: IBAcquisitions,
			authType: 'auth',
			layout: { type: 'dashboard', props: { footerDisplay: true, asideType: 'IBDashboard' } },
			sub: null,
		},
		{
			route: 'ib-dashboard/client-list',
			component: IBClientsList,
			authType: 'auth',
			layout: { type: 'dashboard', props: { footerDisplay: true, asideType: 'IBDashboard', padding: '0' } },
			sub: null,
		},
		// {
		// 	route: 'ib-dashboard/client-list/:id',
		// 	component: IBClientsListDetail,
		// 	authType: 'auth',
		// 	layout: { type: 'normal', props: { footerDisplay: true } },
		// 	sub: null,
		// },
		{
			route: 'ib-dashboard/accounts',
			component: IBAccounts,
			authType: 'auth',
			layout: { type: 'dashboard', props: { footerDisplay: true, asideType: 'IBDashboard', padding: '0' } },
			sub: null,
		},
		{
			route: 'ib-dashboard/rewards',
			component: IBRewards,
			authType: 'auth',
			layout: { type: 'dashboard', props: { footerDisplay: true, asideType: 'IBDashboard' } },
			sub: null,
		},
		{
			route: 'ib-dashboard/transactions',
			component: IBTransactions,
			authType: 'auth',
			layout: { type: 'dashboard', props: { footerDisplay: true, asideType: 'IBDashboard' } },
			sub: null,
		},

		{
			route: 'dashboard/forex',
			component: ForexAccount,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'dashboard/forex/history/:id',
			component: ForexHistory,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: 'insurance',
			component: insurance,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'dashboard/profile',
			component: DashboardProfile,
			authType: 'auth',
			layout: { type: 'dashboard', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: 'dashboard/security',
			component: DashboardSecurity,
			authType: 'auth',
			layout: { type: 'dashboard', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: 'dashboard/api-key',
			component: APIKey,
			authType: 'auth',
			layout: { type: 'dashboard', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: 'dashboard/api-key/docs',
			component: DashboardAPIkeyDoc,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'dashboard/api-key/manage',
			component: ManageApiKey,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: 'dashboard/security/google-auth',
			component: DashboardSecurityGoogleAuth,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: 'dashboard/security/anti-phishing',
			component: DashboardSecurityAntiPhishing,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: 'dashboard/security/delete-account',
			component: DashboardSecurityDeleteAccount,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: 'dashboard/security/freeze-account',
			component: DashboardSecurityFreezeAccount,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		//TODO: disables Temporary until backend be ready
		// {
		// 	route: 'dashboard/security/device-management',
		// 	component: DashboardSecurityDeviceManagement,
		// 	authType: 'auth',
		// 	layout: { type: 'normal', props: { footerDisplay: false } },
		// 	sub: null,
		// },
		{
			route: 'dashboard/security/account-activities',
			component: DashboardSecurityAccountActivities,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: 'trading-bot',
			component: TradingBot,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'margin-trade-data',
			component: MarginTradeData,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'forex-terminal/:loginId',
			component: ForexTerminal,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: 'forex-terminal',
			component: ForexTerminal,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: 'halving',
			component: Halving,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'signal',
			component: Signal,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: 'referral',
			component: Referral,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'referral/setting',
			component: ReferralSetting,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: 'referral/referees/:referralId',
			component: RefereesList,
			authType: 'auth',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: 'campaign/new-comers',
			component: NewComersCampaign,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'campaign/trade-win',
			component: TradeLotteryCampaign,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'event-futures',
			// component: FutureEvents,
			component: HalloweenCampaign,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: false } },
			sub: null,
		},
		{
			route: 'event-futures/:id',
			component: HalloweenCampaign,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
		{
			route: 'events-calender',
			component: EventsCalendar,
			authType: 'guardless',
			layout: { type: 'normal', props: { footerDisplay: true } },
			sub: null,
		},
	];
}

export function RoutesLayoutHandler(props) {
	let layout = props.layout;

	if (layout === null) {
		return props.children;
	} else {
		return layout.type === 'normal' ? (
			<Layout {...layout.props}>{props.children}</Layout>
		) : layout.type === 'dashboard' && !layout.props.asideType ? (
			<DashboardLayout {...layout.props}>{props.children}</DashboardLayout>
		) : layout.type === 'dashboard' && layout.props.asideType ? (
			<NewDashboardLayout {...layout.props}>{props.children}</NewDashboardLayout>
		) : (
			''
		);
	}
}
