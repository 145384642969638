import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal } from '@coinlocally/cly-core';
import { ModalMainContainer } from './profile-lang-modal.style';
import { POSTEmailLang } from 'api/coinlocally/v1/users';
import { changeProfileLanguage } from 'state-manager/reducer/profile';
import toast from 'react-hot-toast';
import { useAppSelector } from 'hooks/redux-helper';
import { useTranslation } from 'react-i18next';

type TLang = 'FA' | 'DE' | 'ES' | 'RU' | 'AR' | 'EN';
const ProfileLangModal = () => {
	const { t } = useTranslation('modals');

	const dispatch = useDispatch();
	const [lang, setLang] = useState<TLang | null>(null);
	const [btnLoading, setBtnLoading] = useState<boolean>(false);

	const isLogin = useAppSelector(state => state.Profile.isLogin);
	const userSelectedLanguage = useAppSelector(state => state.Profile.language);

	const status = userSelectedLanguage === null && isLogin;

	const onConfirmHandler = () => {
		if (lang) {
			setBtnLoading(true);
			POSTEmailLang({ language: lang }).then(() => {
				dispatch(changeProfileLanguage(lang));
			});
		} else {
			toast.error(t('please-chose-language-first'));
		}
	};

	return (
		status && (
			<Modal maxWidth={468} onClose={() => {}} status={status}>
				<ModalMainContainer>
					<h3>{t('choose-language')}</h3>
					<div className='modal_body'>
						<h4>{t('choose-your-communication-channel')}</h4>
						<p>{t('please-choose-your-preferred-language-below-to-receive-emails-in-your-desired-language')} </p>
						<p>
							<b>{t('note')}</b>
							{t('you-can-change-the-language-after-logging-in-at-any-time-by-following-these-steps')}
						</p>
						<ol>
							<li>{t('click-on-asset-located-on-the-right-side-of-the-header')}</li>
							<li>{t('choose-the-dashboard-option-on-the-top-right-side-of-the-page')}</li>
							<li>{t('click-on-the-communication-channel-to-access-the-language-settings')}</li>
						</ol>
						<div className='lang_field'>
							<div className={`${lang === 'EN' ? 'active' : ''} card_row`} onClick={() => setLang('EN')}>
								<p>Click Here to receive Coinlocally Emails in English Language.</p>
							</div>
							<div className={`${lang === 'RU' ? 'active' : ''} card_row`} onClick={() => setLang('RU')}>
								<p>Для получения электронных писем от Coinlocally на русском языке, нажмите здесь.</p>
							</div>
							<div className={`${lang === 'ES' ? 'active' : ''} card_row`} onClick={() => setLang('ES')}>
								<p>Para recibir correos electrónicos de Coinlocally en español, haz clic aquí.</p>
							</div>
							<div className={`${lang === 'FA' ? 'active' : ''} card_row`} onClick={() => setLang('FA')}>
								<p style={{ direction: 'rtl' }}>برای دریافت ایمیل های Coinlocally به زبان فارسی، اینجا کلیک کنید</p>
							</div>
							<div className={`${lang === 'DE' ? 'active' : ''} card_row`} onClick={() => setLang('DE')}>
								<p>Um E-Mails von Coinlocally auf Deutsch zu erhalten, klicken Sie hier.</p>
							</div>
							<div className={`${lang === 'AR' ? 'active' : ''} card_row`} onClick={() => setLang('AR')}>
								<p style={{ direction: 'rtl' }}>
									للحصول على رسائل البريد الإلكتروني من Coinlocally باللغة العربية، انقر هنا.
								</p>
							</div>
						</div>
					</div>
					<Button size='medium' variant='primary' onClick={onConfirmHandler} isLoading={btnLoading}>
						Confirm
					</Button>
				</ModalMainContainer>
			</Modal>
		)
	);
};

export default ProfileLangModal;
