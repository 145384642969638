import { useAppSelector } from 'hooks/redux-helper';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function DashboardAsideConfig() {
	const { t } = useTranslation('aside');
	const ticketCount = useSelector((state: any) => state.Profile.haveTicket);
	const referralType = useAppSelector(state => state.Profile.referralType);
	const Items = [
		{
			title: t('dashboard'),
			icon: 'fas fa-th-large',
			link: '/dashboard/profile',
		},
		{
			title: t('overview'),
			icon: 'fal fa-chart-pie',
			submenu: [
				{
					title: t('futures'),
					link: '/overview/futures',
				},
			],
		},
		{
			title: t('security'),
			icon: 'fal fa-fingerprint',
			link: '/dashboard/security',
		},
		{
			title: t('kyc'),
			icon: 'fal fa-address-card',
			link: '/KYC',
		},

		{
			title: t('support-ticket'),
			icon: 'fal fa-headset',
			link: '/supportTicket',
			badge: ticketCount,
		},
		{
			title: t('earn'),
			icon: 'fal fa-piggy-bank',
			link: '/account/saving',
		},
		{
			title: t('api-key'),
			icon: 'fal fa-key',
			link: '/dashboard/api-key',
		},
		{
			title: t('rewards'),
			icon: 'fal fa-swords',
			link: '/rewards',
		},
		{
			title: t('fee-rates'),
			icon: 'fal fa-badge-percent',
			link: '/fee-rates',
		},
		{
			title: t('history'),
			icon: 'fal fa-history',
			submenu: [
				{
					title: t('deposit-history'),
					link: '/history/deposit',
				},
				{
					title: t('withdraw-history'),
					link: '/history/withdraw',
				},
				{
					title: t('fiat-history'),
					link: '/history/fiat',
				},
				{
					title: t('spot-history'),
					link: '/history/spot',
				},
				{
					title: t('futures-history'),
					link: '/history/futures',
				},
				{
					title: t('transfer-history'),
					link: '/history/transfer',
				},
			],
		},
	];

	return Items;
}

export default DashboardAsideConfig;
