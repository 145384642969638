import axios from 'axios';
import toast from 'react-hot-toast';

class RequestHandler extends EventTarget {
	accessTokenKeyName = 'access_auth_token';
	refreshTokenKeyName = 'refresh_auth_token';

	#baseUrl = '';
	#authorizationHeader = '';
	#axiosInstance = null;
	#isQueueProcessing = false;
	#queue = [];

	constructor() {
		super();

		this.#initiateAxiosInstance();
		this.#initiateAuthorizationHeader();

		this.addEventListener('handleRequestsQueue', this.#queueRequestsHandler, false);
	}

	#initiateAxiosInstance() {
		this.#baseUrl = 'https://api.coinlocally.com/';

		if (process.env.REACT_APP_DEVELOPMENT_MODE && process.env.REACT_APP_DEVELOPMENT_MODE === 'DEVELOPMENT') {
			this.#baseUrl = 'http://localhost:3000/';
		} else if (process.env.REACT_APP_DEVELOPMENT_MODE && process.env.REACT_APP_DEVELOPMENT_MODE === 'STAGE') {
			this.#baseUrl = 'https://api.coinlocaly.com/';
		} else {
			this.#baseUrl = 'https://api.coinlocally.com/';
		}

		this.#axiosInstance = axios.create({
			baseURL: this.#baseUrl,
		});
	}

	clearHeader() {
		this.#authorizationHeader = '';
	}

	#initiateAuthorizationHeader() {
		this.#authorizationHeader = localStorage.getItem(this.accessTokenKeyName)
			? `Bearer ${JSON.parse(localStorage.getItem(this.accessTokenKeyName)).token}`
			: '';
	}

	getPathname() {
		return window.location.pathname;
	}

	async #authorizationHandler() {
		if (localStorage.getItem(this.refreshTokenKeyName)) {
			const refreshToken = JSON.parse(localStorage.getItem(this.refreshTokenKeyName));
			const accessToken = JSON.parse(localStorage.getItem(this.accessTokenKeyName));

			const accessTokenMaxExpiredTimestamp = accessToken ? new Date(accessToken.expiredAt).getTime() - 10000 : Date.now() - 10000;
			const refreshTokenMaxExpiredTimestamp = refreshToken ? new Date(refreshToken.expiredAt).getTime() : Date.now() - 10000;

			if (refreshTokenMaxExpiredTimestamp <= Date.now()) {
				localStorage.removeItem(this.accessTokenKeyName);
				localStorage.removeItem(this.refreshTokenKeyName);
				const language = localStorage.getItem('i18nextLng') ?? 'en';
				window.location.href = `/${language}/login?callbackUrl=${this.getPathname()}`;
			} else {
				if (accessTokenMaxExpiredTimestamp <= Date.now()) {
					const { data } = await axios.put(`${this.#baseUrl}api/v1/users/session`, { refreshToken: refreshToken.token });

					localStorage.setItem(
						this.accessTokenKeyName,
						JSON.stringify({
							token: data.result.token,
							expiredAt: data.result.expiredAt,
						}),
					);

					localStorage.setItem(
						this.refreshTokenKeyName,
						JSON.stringify({
							token: data.result.refreshToken,
							expiredAt: data.result.refreshTokenExpiredAt,
						}),
					);
				}

				const newAccessToken = JSON.parse(localStorage.getItem(this.accessTokenKeyName));

				if (new Date(newAccessToken.expiredAt).getTime() > Date.now()) {
					this.#authorizationHeader = `Bearer ${newAccessToken.token}`;
				}
			}
		}
	}

	async #queueRequestsHandler() {
		this.#isQueueProcessing = true;

		while (this.#queue.length > 0) {
			try {
				await this.#authorizationHandler();
			} catch (error) {
				localStorage.removeItem(this.accessTokenKeyName);
				localStorage.removeItem(this.refreshTokenKeyName);
				const language = localStorage.getItem('i18nextLng') ?? 'en';
				window.location.href = `/${language}/login?callbackUrl=${this.getPathname()}`;
			}

			const currentIndex = this.#queue.length - 1;
			const request = this.#queue[currentIndex];

			this.#initiateAuthorizationHeader();

			const language = localStorage.getItem('i18nextLng') ?? 'en';

			const result = this.#axiosInstance(request.url, {
				method: request.method,
				data: request.data,
				headers: {
					Authorization: this.#authorizationHeader,
					'content-language': language,
				},
			});

			this.dispatchEvent(
				new CustomEvent(`request:${currentIndex}`, {
					detail: result,
				}),
			);

			this.#queue.pop();
		}

		this.#isQueueProcessing = false;
	}

	async call({ url, method, data = {} }) {
		return new Promise((resolve, reject) => {
			const currentlenght = this.#queue.push({ url, method, data });

			if (!this.#isQueueProcessing) {
				this.dispatchEvent(new CustomEvent('handleRequestsQueue'));
			}

			if (Object.keys(data).length > 0) {
				for (const key of Object.keys(data)) {
					if (data[key] === '') {
						delete data[key];
					}
				}
			}

			this.addEventListener(
				`request:${currentlenght - 1}`,
				async event => {
					try {
						const result = await event.detail;

						if (result.data.message && result.status === 200) {
							toast.success(result.data.message, { style: { zIndex: 2000 } });
						}

						resolve(result);
					} catch (error) {
						if (!error?.response?.data?.message) {
							if (error.response?.status === 403) {
								toast.error('You are not authorized to execute this request!', { style: { zIndex: 2000 } });
							}
						}
						if (error.response?.data?.code === 3 || error.response?.data?.code === 4 || error.response?.data?.code === 100) {
							localStorage.removeItem(this.accessTokenKeyName);
							localStorage.removeItem(this.refreshTokenKeyName);
							const language = localStorage.getItem('i18nextLng') ?? 'en';
							window.location.href = `/${language}/login?callbackUrl=${this.getPathname()}`;
						} else {
							if (error?.response?.data?.message) {
								toast.error(error?.response?.data?.message, { style: { zIndex: 2000 } });
							}
						}

						reject(error);
					}
				},
				{
					once: true,
				},
			);
		});
	}
}

export default new RequestHandler();
