import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { envResolver } from 'shared/utils';
import './assets/css/general/fontAwsome.css';
import './assets/css/general/general.css';
import App from './component/app/app';
import './i18n';
import Store from './state-manager/store';

Sentry.init({
	dsn: 'https://bfdd4826a552f0da3cceeaf7e749ee0e@o4506585300008960.ingest.sentry.io/4506609645256704',
	...(process.env.NODE_ENV !== 'development' && {
		release: 'cly-front@' + envResolver.VERSION,
	}),
	tunnel: 'https://apm.coinlocally.com/tunnel',
	beforeSend(event, hint) {
		if (event.exception && event.exception.values) {
			for (const item of event.exception.values) {
				if (item.value?.includes('Request') && item.value.includes('status code')) {
					return null;
				}
			}
		}
		return event;
	},
	integrations: [
		new Sentry.BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV6Instrumentation(
				React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			),
		}),
		new Sentry.BrowserTracing({ tracingOrigins: ['*'], traceXHR: false, traceFetch: false }),
		new Sentry.Replay(),
		new Sentry.Breadcrumbs({ console: false, xhr: false, fetch: false }),
	],
	tracesSampleRate: 1.0,
	tracePropagationTargets: ['localhost', /^/],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<GoogleOAuthProvider clientId={envResolver.GOOGLE_AUTH_CLIENT_ID}>
		<Provider store={Store}>
			<App />
		</Provider>
	</GoogleOAuthProvider>,
);
