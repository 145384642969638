import { flush, injectGlobal } from '@emotion/css';
import { useEffect } from 'react';

const useCrispChatboxHide = () => {
	useEffect(() => {
		injectGlobal`
      #crisp-chatbox{
        display: none !important;
      }
    `;

		return () => flush();
	}, []);
};

export { useCrispChatboxHide };
