import {
	DashboardEqualOutline,
	DollarSquareOutline,
	LinkOutline,
	RankingOutline,
	TelegramOutline,
	TrendUpOutline,
	UsersOutline,
} from '@coinlocally/cly-core/icons';
import { useTranslation } from 'react-i18next';

function AffiliateAsideConfig() {
	const { t } = useTranslation('aside');

	const aside_config = [
		{
			title: t('dashboard'),
			icon: <DashboardEqualOutline />,
			link: `affiliate-dashboard`,
		},
		{
			title: t('referral'),
			icon: <UsersOutline />,
			link: `affiliate-dashboard/referral`,
		},
		{
			title: t('campaign-links'),
			icon: <LinkOutline />,
			link: `affiliate-dashboard/campaign-links`,
		},
		{
			title: t('campaign-performance'),
			icon: <TrendUpOutline />,
			link: `affiliate-dashboard/campaigns-performance`,
		},
		{
			title: t('campaign-auto-share'),
			icon: <TelegramOutline />,
			link: `affiliate-dashboard/auto-share`,
		},
		{
			title: t('earnings-history'),
			icon: <DollarSquareOutline />,
			link: `affiliate-dashboard/earnings-history`,
		},
		{
			title:t('referees-leaderboard'),
			icon: <RankingOutline />,
			link: `affiliate-dashboard/referees-leaderboard`,
		},
	];

	return aside_config;
}

export default AffiliateAsideConfig;
