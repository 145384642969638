/* eslint-disable @typescript-eslint/no-explicit-any */
import { IBotGenericResponse } from 'api/bot/_shared/shared.dto';
import axios, { AxiosInstance } from 'axios';
import toast from 'react-hot-toast';
import { envResolver } from 'shared/utils';

class RequestHandler extends EventTarget {
	accessTokenKeyName = 'access_auth_token';
	refreshTokenKeyName = 'refresh_auth_token';
	botTokenKeyName = 'access_auth_trader_bot_token';

	axiosInstance: AxiosInstance = axios.create();
	isQueueProcessing = false;
	queue: { url: string; method: 'get' | 'post' | 'put' | 'delete'; data: any; baseUrl?: string; type: 'bot' | 'main' }[] = [];

	constructor() {
		super();
		this.addEventListener('handleRequestsQueue', this.queueRequestsHandler, false);
	}

	clearHeader() {
		localStorage.removeItem(this.accessTokenKeyName);
		localStorage.removeItem(this.refreshTokenKeyName);
		localStorage.removeItem(this.botTokenKeyName);
	}

	private getPathname() {
		return window.location.pathname;
	}

	async authorizationHandler() {
		let _refreshToken = localStorage.getItem(this.refreshTokenKeyName);
		let _accessToken = localStorage.getItem(this.accessTokenKeyName);
		let _botToken = localStorage.getItem(this.botTokenKeyName);
		if (_refreshToken && _accessToken && _botToken) {
			const refreshToken = JSON.parse(_refreshToken);
			const accessToken = JSON.parse(_accessToken);
			const botToken = JSON.parse(_botToken);

			const refreshTokenMaxExpiredTimestamp = new Date(refreshToken.expiredAt).getTime();
			const accessTokenMaxExpiredTimestamp = new Date(accessToken.expiredAt).getTime() - 10000;
			const botTokenMaxExpiredTimestamp = new Date(botToken.expiredAt).getTime() - 10000;

			if (refreshTokenMaxExpiredTimestamp <= Date.now()) {
				this.clearHeader();
				const language = localStorage.getItem('i18nextLng') ?? 'en';
				window.location.href = `/${language}/login?callbackUrl=${this.getPathname()}`;
			} else {
				if (accessTokenMaxExpiredTimestamp <= Date.now() || botTokenMaxExpiredTimestamp <= Date.now()) {
					const { data } = await axios.put(`${envResolver.API_BASE_URL}api/v1/users/session`, {
						refreshToken: refreshToken.token,
					});

					localStorage.setItem(
						this.accessTokenKeyName,
						JSON.stringify({
							token: data.result.token,
							expiredAt: data.result.expiredAt,
						}),
					);

					localStorage.setItem(
						this.refreshTokenKeyName,
						JSON.stringify({
							token: data.result.refreshToken,
							expiredAt: data.result.refreshTokenExpiredAt,
						}),
					);

					if (data.result.tradeBotAccessToken) {
						localStorage.setItem(
							this.botTokenKeyName,
							JSON.stringify({
								token: data.result.tradeBotAccessToken,
								expiredAt: data.result.tradeBotAccessTokenExpiredAt,
							}),
						);
					}
				}
			}
		}
	}

	async queueRequestsHandler() {
		this.isQueueProcessing = true;

		while (this.queue.length > 0) {
			try {
				await this.authorizationHandler();
			} catch (error) {
				this.clearHeader();
				const language = localStorage.getItem('i18nextLng') ?? 'en';
				window.location.href = `/${language}/login?callbackUrl=${this.getPathname()}`;
			}

			const currentIndex = this.queue.length - 1;
			const request = this.queue[currentIndex];

			const tokenKeyName = request.type === 'bot' ? this.botTokenKeyName : this.accessTokenKeyName;
			const jwtToken = JSON.parse(localStorage.getItem(tokenKeyName) ?? '{}').token;
			const token = jwtToken ? `Bearer ${jwtToken}` : null;

			const language = localStorage.getItem('i18nextLng') ?? 'en';

			const result = this.axiosInstance(request.url, {
				method: request.method,
				data: request.data,
				...(request.baseUrl && {
					baseURL: request.baseUrl,
				}),
				headers: {
					...(token && {
						Authorization: token,
					}),
					'content-language': language,
				},
			});

			this.dispatchEvent(
				new CustomEvent(`request:${currentIndex}`, {
					detail: result,
				}),
			);

			this.queue.pop();
		}

		this.isQueueProcessing = false;
	}

	async call<T>(options: { url: string; method: 'get' | 'post' | 'put' | 'delete'; data?: any; type?: 'main' | 'bot' }) {
		type PromiseType = typeof options.type extends 'bot' ? IBotGenericResponse<T> : T;
		return new Promise<PromiseType>((resolve, reject) => {
			const botBaseUrl = envResolver.BOT_API_BASE_URL;
			const mainBaseUrl = envResolver.API_BASE_URL;

			const currentLength = this.queue.push({
				url: options.url,
				method: options.method,
				data: options.data,
				...(!options.url.includes('https://') && {
					baseUrl: options.type === 'bot' ? botBaseUrl : mainBaseUrl,
				}),
				type: options.type === 'bot' ? 'bot' : 'main',
			});

			if (!this.isQueueProcessing) {
				this.dispatchEvent(new CustomEvent('handleRequestsQueue'));
			}

			this.addEventListener(
				`request:${currentLength - 1}`,
				async (event: any) => {
					if (options.type === 'bot') {
						try {
							const result = await event.detail;
							resolve(result.data.data);
						} catch (error: any) {
							for (const errorMessage of error.response.data.errors) {
								toast.error(errorMessage);
							}
							reject(error);
						}
					} else {
						try {
							const result = await event.detail;

							if (result.data.message && result.status === 200) {
								toast.success(result.data.message, { style: { zIndex: 2000 } });
							}

							resolve(result.data);
						} catch (error: any) {
							if (!error?.response?.data?.message) {
								if (error.response?.status === 403) {
									toast.error('You are not authorized to execute this request!', { style: { zIndex: 2000 } });
								}
							}
							if (
								error.response?.data?.code === 3 ||
								error.response?.data?.code === 4 ||
								error.response?.data?.code === 100
							) {
								this.clearHeader();
								const language = localStorage.getItem('i18nextLng') ?? 'en';
								window.location.href = `/${language}/login?callbackUrl=${this.getPathname()}`;
							} else {
								if (error?.response?.data?.message) {
									toast.error(error?.response?.data?.message, { style: { zIndex: 2000 } });
								}
							}

							reject(error);
						}
					}
				},
				{
					once: true,
				},
			);
		});
	}
}

const requestHandler = new RequestHandler();
export default requestHandler;
