import Badge from '@mui/material/Badge';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import asideConfig from './aside.config';
import { Aside, MenuButton } from './style/dashboard-aside.style';

function DashboardAside() {
	const role = useSelector((state: any) => state.Profile.role);
	const questReward = useSelector((state: any) => state.Profile.questReward);
	const location = useLocation();
	const config = asideConfig();
	const [menueStatus, setMenuStatus] = useState('');

	const { i18n } = useTranslation();
	let language = i18n.language;

	const openMenu = (status: any) => {
		if (menueStatus === status) setMenuStatus('');
		else setMenuStatus(status);
	};

	const ActiveMenuHandler = (json: any) => {
		var flag = false;

		json?.submenu?.map((data: any) => {
			if (location?.pathname === data.link) {
				flag = true;
			}
		});

		return flag;
	};

	const AsideItemProvider = config.map((item, index) => {
		if (item.title) {
			if (item?.submenu) {
				if ((item.title === 'Partnership' && role === 'PARTNER') || item.title !== 'Partnership') {
					return (
						<MenuButton
							onClick={() => openMenu(item.title)}
							// @ts-ignore
							count={item.submenu.length}
							style={{ marginBottom: item.title === 'History' ? '20px' : '' }}
							className={menueStatus === item.title || ActiveMenuHandler(item) ? 'active_menu' : ''}
							key={`proile_aside_items${index}`}
						>
							<div>
								<i className={item.icon}></i>
								{item.title}
								<i className='fal fa-angle-right angle'></i>
							</div>
							<ul>
								{item.submenu.map((data, count) => (
									<li key={`profile_aside_submenu_${count}`}>
										<NavLink to={`/${language}${data.link}`}>
											<i className='fad fa-circle-notch'></i>
											{data.title}
										</NavLink>
									</li>
								))}
							</ul>
						</MenuButton>
					);
				}
			} else {
				return (
					<li key={`profile_aside_simple_link_${index}`}>
						<NavLink to={`/${language}${item.link}`}>
							<i className={item.icon}></i>
							{item.title}
							{item?.badge ? <Badge badgeContent={item.badge}></Badge> : ''}
							{item.title === 'Rewards' ? (
								questReward ? (
									<Badge
										className='quest_reward'
										badgeContent={`+${questReward.rewardAmount}${questReward.rewardAsset}`}
									></Badge>
								) : (
									''
								)
							) : (
								''
							)}
						</NavLink>
					</li>
				);
			}
		}
	});

	return (
		<Aside>
			<ul>{AsideItemProvider}</ul>
		</Aside>
	);
}

export default React.memo(DashboardAside);
