import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';

i18n.use(LanguageDetector)
	.use(ChainedBackend)
	.use(initReactI18next)
	.init({
		fallbackLng: 'en',

		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		supportedLngs: ['en', 'fa', 'ru', 'ar', 'de', 'es'],
		backend: {
			backends: [
				HttpBackend, // if you need to check translation files from server
			],
			backendOptions: [
				{
					loadPath: '/locales/{{lng}}/{{ns}}.json',
				},
			],
		},
	});

export default i18n;
