import Layout from 'component/layout/layout';
import { FC, ReactNode } from 'react';
import Aside from './aside';
import { ContentField, MainField } from './style';
import { Outlet } from 'react-router-dom';
import { TAsideTypes } from './types';

const NewDashboardLayout: FC<{ children: ReactNode; footerDisplay: boolean; padding?: number; asideType: TAsideTypes }> = ({
	children,
	footerDisplay,
	padding,
	asideType,
}) => {
	return (
		<Layout footerDisplay={footerDisplay} forceLight={false}>
			<MainField>
				<div>
					<Aside asideType={asideType} />
				</div>
				<ContentField style={{ ...(padding !== undefined ? { padding: padding } : {}) }}>
					{children}
					<Outlet />
				</ContentField>
			</MainField>
		</Layout>
	);
};

export default NewDashboardLayout;
