import { IBaseResponseModel } from 'shared/types/general.type';
import requestHandler from 'utils/requestHandler';
import {
	IAntiPhishingPhraseRequest,
	IDeleteUserRequest,
	IForgetPasswordRequest,
	IFreezeUserRequest,
	IGetSessionTokens,
	IGetUserProfile,
	IGoogleAuthConfirmOtps,
	ILoginRequest,
	IOAuthRequest,
	IOtpConfirmation,
	IPostEmailLang,
	IPutUserProfileRequest,
	IRegisterRequest,
	IUserProfileInfo,
} from './users.dto';
const BASE_URL = 'api/v1/users';

export const PUTAntiPhishingPhrase = async (data: IAntiPhishingPhraseRequest) => {
	let _data: IAntiPhishingPhraseRequest = data;

	if (!_data.emailOtp) delete _data.emailOtp;
	if (!_data.smsOtp) delete _data.smsOtp;
	if (!_data.totp) delete _data.totp;
	return requestHandler
		.call<IBaseResponseModel<string>>({ url: `${BASE_URL}/anti-phishing-phrase`, method: 'put', data: _data })
		.then(res => res.result);
};

export const DELETEUserAccount = async (data: IDeleteUserRequest) => {
	let _data: IDeleteUserRequest = data;

	if (!_data.emailOtp) delete _data.emailOtp;
	if (!_data.smsOtp) delete _data.smsOtp;
	if (!_data.totp) delete _data.totp;

	return requestHandler.call<IBaseResponseModel<string>>({ url: `${BASE_URL}`, method: 'delete', data: _data }).then(res => {
		localStorage.removeItem('access_auth_token');
		localStorage.removeItem('refresh_auth_token');
		window.location.href = '/en';

		return res.result;
	});
};

export const POSTUserFreezeAccount = async (data: IFreezeUserRequest) => {
	let _data: IFreezeUserRequest = data;

	if (!_data.emailOtp) delete _data.emailOtp;
	if (!_data.smsOtp) delete _data.smsOtp;
	if (!_data.totp) delete _data.totp;

	return requestHandler.call<IBaseResponseModel<string>>({ url: `${BASE_URL}/freezed`, method: 'post', data: _data }).then(res => {
		return res.result;
	});
};

export const DELETEUserGoogle2FA = async (data: IOtpConfirmation) => {
	let _data: IOtpConfirmation = data;

	if (!_data.emailOtp) delete _data.emailOtp;
	if (!_data.smsOtp) delete _data.smsOtp;
	if (!_data.totp) delete _data.totp;

	return requestHandler.call<IBaseResponseModel<string>>({ url: `${BASE_URL}/google-2fa`, method: 'delete', data: _data }).then(res => {
		return res.result;
	});
};

export const DELETEUserPhoneNumber = async (data: IOtpConfirmation) => {
	let _data: IOtpConfirmation = data;

	if (!_data.emailOtp) delete _data.emailOtp;
	if (!_data.smsOtp) delete _data.smsOtp;
	if (!_data.totp) delete _data.totp;

	return requestHandler.call<IBaseResponseModel<string>>({ url: `${BASE_URL}/phone-number`, method: 'delete', data: _data }).then(res => {
		return res.result;
	});
};

export const DELETEUserAntiPhishing = async (data: IOtpConfirmation) => {
	let _data: IOtpConfirmation = data;

	if (!_data.emailOtp) delete _data.emailOtp;
	if (!_data.smsOtp) delete _data.smsOtp;
	if (!_data.totp) delete _data.totp;

	return requestHandler
		.call<IBaseResponseModel<string>>({ url: `${BASE_URL}/anti-phishing-phrase`, method: 'delete', data: _data })
		.then(res => {
			return res.result;
		});
};

export const DELETEUserCredential = async (id: number, data: IOtpConfirmation) => {
	let _data: IOtpConfirmation = data;

	if (!_data.emailOtp) delete _data.emailOtp;
	if (!_data.smsOtp) delete _data.smsOtp;
	if (!_data.totp) delete _data.totp;

	return requestHandler
		.call<IBaseResponseModel<string>>({ url: `${BASE_URL}/credentials/personal/${id}`, method: 'delete', data: _data })
		.then(res => {
			return res.result;
		});
};

export const DELETEAllUserCredential = async (data: IOtpConfirmation) => {
	let _data: IOtpConfirmation = data;

	if (!_data.emailOtp) delete _data.emailOtp;
	if (!_data.smsOtp) delete _data.smsOtp;
	if (!_data.totp) delete _data.totp;

	return requestHandler
		.call<IBaseResponseModel<string>>({ url: `${BASE_URL}/credentials/personal`, method: 'delete', data: _data })
		.then(res => {
			return res.result;
		});
};

export const POSTEmailLang = async (data: { language: 'FA' | 'DE' | 'HY' | 'ES' | 'RU' | 'AR' | 'EN' | null }) => {
	return requestHandler.call<IPostEmailLang>({ url: `${BASE_URL}/language`, method: 'post', data }).then(res => res.result);
};

export const GETUserProfileInfo = async () => {
	return requestHandler
		.call<IBaseResponseModel<IUserProfileInfo>>({ url: `${BASE_URL}/profile-info`, method: 'get' })
		.then(res => res.result);
};

export const PUTUserProfileInfo = async (data: IPutUserProfileRequest) => {
	return requestHandler
		.call<IBaseResponseModel<string>>({ url: `${BASE_URL}/profile-info`, method: 'put', data })
		.then(res => res.result);
};

export const POSTUserProfileAvatar = async (data: FormData) => {
	return requestHandler
		.call<IBaseResponseModel<string>>({ url: `${BASE_URL}/profile-info/avatar`, method: 'post', data })
		.then(res => res.result);
};

export const DELETEUserProfileAvatar = async () => {
	return requestHandler
		.call<IBaseResponseModel<string>>({ url: `${BASE_URL}/profile-info/avatar`, method: 'delete' })
		.then(res => res.result);
};

export const POSTUserRegister = async (data: IRegisterRequest) => {
	return requestHandler
		.call<IBaseResponseModel<string[] | IGetSessionTokens>>({ url: `${BASE_URL}`, method: 'post', data })
		.then(res => res.result);
};

export const PUTUserResetPassword = async (data: IForgetPasswordRequest) => {
	return requestHandler
		.call<IBaseResponseModel<string[] | undefined>>({ url: `${BASE_URL}/password`, method: 'put', data })
		.then(res => res.result);
};

export const POSTUserSession = async (data: ILoginRequest) => {
	return requestHandler
		.call<IBaseResponseModel<string[] | IGetSessionTokens>>({ url: `${BASE_URL}/session`, method: 'post', data })
		.then(res => res.result);
};

export const POSTUserSessionGoogleAuth = async (data: IOAuthRequest) => {
	return requestHandler
		.call<IBaseResponseModel<IGoogleAuthConfirmOtps | IGetSessionTokens>>({ url: `${BASE_URL}/session/oauth`, method: 'post', data })
		.then(res => res.result);
};

export const GETUserProfile = async () => {
	return requestHandler.call<IBaseResponseModel<IGetUserProfile>>({ url: `${BASE_URL}/profile`, method: 'get' }).then(res => res.result);
};
