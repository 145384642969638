export const OldThemeRoutes: string[] = [
	'nft',
	'account/saving',
	'referees',
	'supportTicket',
	'activity-log',
	'notification',
	'vip',
	'VIP',
	'balances',
	'small-balances',
	'rewards',
	'fee-rates',
	'KYC',
	'FAQ',
	'history',
	'overview/futures',
	'about-us',
	'Terms',
	'policy',
	'landing/demo',
	'landing/apikey',
	'market',
	'landing/survey',
	'community',
	'CLYC',
	'rules',
	'affiliate',
	'invoice',
	'announcement-article',
	'announcement',
	'announcement/category',
	'Partnership',
	'leaderboard',
	'profile',
	'Partnership/application',
	'withdraw',
	'deposit',
	'login',
	'register',
	'forget-password',
	'buy-crypto/mercuryo',
	'download',
	'landing/3commas',
	'landing/testflight',
	'reward-hub',
	'task-center',
	'vip-portal',
	'landing/ai-multilanguage',
	'landing/xmas-cashback',
	'black-friday',
	'tournament',
	'task-center/rewards',
	'saving',
	'funding-rate-history',
	'ib-dashboard',
	'ib-dashboard/links',
	'ib-dashboard/acquisitions',
	'ib-dashboard/client-list',
	'ib-dashboard/accounts',
	'ib-dashboard/rewards',
	'ib-dashboard/transactions',
	'affiliate-dashboard',
	'dashboard/forex',
	'dashboard/forex/history',
	'insurance',
	'dashboard/profile',
	'dashboard/security',
	'dashboard/api-key',
	'dashboard/api-key/docs',
	'dashboard/api-key/manage',
	'dashboard/security/google-auth',
	'dashboard/security/anti-phishing',
	'dashboard/security/delete-account',
	'dashboard/security/freeze-account',
	'dashboard/security/device-management',
	'dashboard/security/account-activities',
	'trading-bot',
	'margin-trade-data',
	'forex-terminal',
	'halving',
	'signal',
	'referral',
	'referral/setting',
	'referral/referees',
	'campaign/new-comers',
	'campaign/trade-win',
];
