import { Footer, Navbar } from '@coinlocally/cly-core';
import { Global } from '@emotion/react';
import { LogoutAPI } from 'api-requests/auth/api-call';
import { getAssets } from 'api-requests/buy-crypto/buy-crypto';
import { getSecurityStrategiesApi, getUserInfo } from 'api-requests/dashboard/profile/user-info';
import { GetAnnouncement, getNotification, viewNotification } from 'api-requests/notification/notification';
import { GETGeoLocation } from 'api/coinlocally/edge';
import ContactUs from 'component/other/contact-us';
import requestHandler from 'customize/requestHandler';
import { useAppSelector } from 'hooks/redux-helper';
import { FC, ReactNode, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { IsLogin, changeIPCountry } from 'state-manager/reducer/profile';
import { themProvider, themeCashProvider } from 'state-manager/reducer/them';
import maskEmail from 'utils/mask-email';
import maskPhone from 'utils/mask-phone';
import RequestHandler from 'utils/requestHandler';

const Layout: FC<{ children: ReactNode; footerDisplay: boolean; forceLight: boolean; navbarType?: 'FULL' | 'MINIMAL' }> = ({
	children,
	footerDisplay = true,
	forceLight,
	navbarType,
}) => {
	const Theme = useSelector((state: any) => state.ThemeProvider.theme);
	const { isLogin, email, phoneNumber, vipLevelName, corporationKyc, advanceKyc, basicKyc, referralType, avatar } = useAppSelector(
		state => state.Profile,
	);

	const { language } = useTranslation().i18n;
	const dispatch = useDispatch();

	const navigate = useNavigate();
	const location = useLocation();

	const [notification, setNotification] = useState<null | any[]>(null);
	const [announcement, setAnnouncement] = useState<null | []>(null);
	const [buyCryptoAssets, setBuyCryptoAssets] = useState<{ name: string; icon: string }[]>([]);

	const [contactUsModalStatus, setContactUsModalStatus] = useState<boolean>(false);

	const [excludeNavIds, setExcludeNavIds] = useState<string[]>(['navbar_forex', 'mobile_navbar_forex', 'mobile_navbar_forex_dashboard']);

	const changeTheme = () => {
		dispatch(themProvider(Theme === 'light' ? 'dark' : 'light'));
		dispatch(themeCashProvider(Theme === 'light' ? 'dark' : 'light'));
	};

	useEffect(() => {
		if (isLogin) {
			getNotification().then(res => {
				setNotification(res.result);
			});
		}
		GetAnnouncement().then(res => {
			setAnnouncement(res);
		});
		getAssets().then(res => {
			let assets = res.fiat.map(fiat => {
				return {
					name: fiat,
					icon: res.config.icons[fiat].png,
				};
			});
			setBuyCryptoAssets(assets);
		});

		if (isLogin) {
			getUserInfo(dispatch);
			getSecurityStrategiesApi(dispatch);
		}

		GETGeoLocation().then(res => {
			if (res.Country !== 'AM') {
				setExcludeNavIds([]);
				dispatch(changeIPCountry(res.Country));
			}
		});
	}, []);

	const onNotificationSeenHandler = (notificationId: number) => {
		if (notification) {
			viewNotification(notificationId);

			let notifications = [...notification];
			let index = notifications.findIndex(item => item.id === notificationId);
			if (index !== -1) {
				notifications[index].viewed = true;
				setNotification(notifications);
			}
		}
	};

	const LogOutClickHandler = () => {
		LogoutAPI().then(() => {
			requestHandler.clearHeader();
			RequestHandler.clearHeader();
			localStorage.removeItem('refresh_auth_token');
			localStorage.removeItem('access_auth_token');
			dispatch(IsLogin(false));
			window.location.reload();
		});
	};

	const openChatHandler = () => {
		document.querySelector<HTMLElement>('[data-id]')?.click();
	};
	const onContactUsClickHandler = () => {
		setContactUsModalStatus(true);
	};
	const onChangeLanguageHandler = (lng: string) => {
		if (lng !== language) {
			navigate(location.pathname.replace(language, lng), { replace: true });
		}
	};

	const profileData: {
		user: string;
		kyc: {
			isVerified: boolean;
			kycLevel: 'Basic' | 'Advanced' | 'Corporation';
		};
		vipLevel: string;
		avatar?: string;
	} | null = !isLogin
		? null
		: {
				kyc: {
					isVerified:
						corporationKyc.status === 'CONFIRMED' || advanceKyc.status === 'CONFIRMED' || basicKyc.status === 'CONFIRMED',
					kycLevel:
						corporationKyc.status === 'CONFIRMED' ? 'Corporation' : advanceKyc.status === 'CONFIRMED' ? 'Advanced' : 'Basic',
				},
				user: email ? maskEmail(email) : maskPhone(phoneNumber!),
				vipLevel: vipLevelName ?? '',
				...(avatar && { avatar: avatar }),
		  };
	return (
		<>
			<Global
				styles={{
					body: {
						backgroundColor: Theme === 'light' ? 'white' : '#181819',
					},
					nav: {
						'*': {
							fontFamily: 'var(--font-product-sans) !important',
						},
					},
					'& .MuiDialog-paperFullWidth': {
						backgroundColor: Theme === 'dark' ? '#1E2328 !important' : 'white',
						backgroundImage: Theme === 'dark' ? 'none !important' : '',
					},
				}}
			/>

			<Navbar
				theme={Theme}
				referralType={referralType}
				type={navbarType ?? 'FULL'}
				isLinkExternal={false}
				isLogin={isLogin}
				language={language}
				changeTheme={changeTheme}
				buyCryptoCurrencies={buyCryptoAssets}
				announcements={announcement}
				notifications={notification}
				profile={profileData}
				excludeItemIds={excludeNavIds}
				onNotificationSeenHandler={onNotificationSeenHandler}
				onLogOutClickHandler={LogOutClickHandler}
				onLanguageClickHandler={onChangeLanguageHandler}
				affiliateSupport={{
					email: 'affiliates@coinlocally.com',
					telegramID: '@coinlocallyAffiliate',
				}}
			/>

			{children}
			{footerDisplay && (
				<Footer
					onChatClick={openChatHandler}
					onContactUs={onContactUsClickHandler}
					onLanguageClickHandler={onChangeLanguageHandler}
				/>
			)}
			{contactUsModalStatus && (
				<ContactUs
					closeContactUs={() => {
						setContactUsModalStatus(false);
					}}
					status={contactUsModalStatus}
				/>
			)}
		</>
	);
};

export default memo(Layout);
