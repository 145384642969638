import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const AffiliateCustomerSupportContainer = styled.div(
	({ theme }) => css`
		width: 200px;
		border-radius: 8px;
		position: fixed;
		text-align: center;
		left: 20px;
		bottom: 20px;

		background: ${theme.mode === 'light'
			? 'linear-gradient(180deg, #ededed 0%, rgba(230, 230, 230, 0) 100%)'
			: 'linear-gradient(180deg, #3A3A3C 0%, rgba(58, 58, 60, 0.2) 100%)'};

		.support_logo {
			margin-top: -40px;
		}

		.content {
			padding: 20px 16px;
			.title {
				font-size: 16px;
				font-weight: 700;
				line-height: 19.41px;
			}

			.bordered {
				border: 1px solid;
				border-image-slice: 1;
				border-width: 1px;
				border-image-source: linear-gradient(to left, transparent 5%, ${theme.colors.border.primary}, transparent 95%);
				margin-top: 12px;
				margin-bottom: 17px;
			}

			.social_container {
				display: flex;
				align-items: center;
				gap: 6px;

				p {
					font-size: 14px;
					font-weight: 300;
					line-height: 20px;
					color: ${theme.colors.text.secondary};
				}

				span {
					display: flex;
					align-items: center;
					gap: 6px;
					.icon_box {
						background-color: ${theme.mode === 'light' ? '#FFFFFF' : '#414143'};
						border-radius: 50%;
						padding: 3px;
					}
				}

				&.email {
					margin-top: 9px;
				}
			}
		}
	`,
);
