import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const SidebarContainer = styled.aside(
	({ theme }) => css`
		.aside_title_container {
			display: none;
		}
		.aside_title_mobile_container {
			display: flex;
			padding: 16px;
			padding-bottom: 12px;
			padding-top: 20px;

			h3 {
				color: ${theme.mode === 'dark' ? '#666666' : '#BEBEC0'};
				margin-bottom: -12px;
				font-size: 14px;
				font-weight: 400;
				line-height: 17px;
				text-align: left;
				letter-spacing: 0.02em;
			}
		}
		.horizontal_view {
			padding: 12px 16px 0;
			width: 100%;
			display: flex;
			gap: 16px;
			overflow-x: auto;
			scroll-snap-type: x mandatory;
			::-webkit-scrollbar {
				display: none;
			}

			.horizontal_item {
				scroll-snap-align: center;
				cursor: pointer;
				display: flex;
				min-width: 100px;
				align-items: center;
				justify-content: center;
				min-width: max-content;
				color: ${theme.colors.text.secondary};
				font-size: 14px;
				font-weight: 300;
				line-height: 17px;
				border-radius: 4px;
				margin-right: 10px;
				&.active {
					color: ${theme.colors.text.primary};
					font-weight: 500;
					background-color: ${theme.colors.background[theme.mode === 'light' ? 'tertiary' : 'quaternary']};
					padding: 8px 10px 7px;
				}
			}
		}

		.vertical_view {
			display: none;
		}

		@media (min-width: 768px) {
			.horizontal_view {
				.horizontal_item {
					font-size: 16px;
					&.active {
						padding: 9px 12px;
					}
				}
			}
		}

		@media (min-width: 1218px) {
			background: ${theme.mode === 'light' ? theme.colors.background.secondary : '#272729'};
			height: 100%;

			.aside_title_mobile_container {
				display: none;
				padding: 20px;
				h3 {
					margin: 0;
				}
			}

			.aside_title_container {
				display: flex;
				padding: 20px;

				h3 {
					color: ${theme.mode === 'dark' ? '#666666' : '#BEBEC0'};
					margin-bottom: -12px;
					font-size: 14px;
					font-weight: 400;
					line-height: 17px;
					text-align: left;
					letter-spacing: 0.02em;
					margin: 0;
				}
			}
			.vertical_view {
				width: 240px;
				display: flex;
				height: 100%;
				flex-direction: column;
				position: sticky;
				top: 64px;
				overflow: auto;
				height: calc(100vh - 50px);

				.vertical_item {
					padding: 12px 20px;
					display: flex;
					align-items: center;
					gap: 12px;
					color: ${theme.colors.text[theme.mode === 'light' ? 'primary' : 'secondary']};
					line-height: 19px;
					cursor: pointer;

					font-size: 16px;
					font-weight: 300;
					line-height: 19px;

					svg {
						width: 24px;
						height: 24px;
						color: ${theme.mode === 'light' ? '#464649' : '#939393'};
					}

					&.active {
						background: ${theme.mode === 'dark' ? '#3A3A3C' : '#000000'};
						color: #ffffff;

						svg {
							color: ${theme.mode === 'light' ? '#FFFFFF' : '#FFFFFF'};
						}
					}

					&:hover {
						background: ${theme.mode === 'light' ? '#E1E3E5' : '#2C2C2E'};
						&.active {
							background: ${theme.mode === 'dark' ? '#3A3A3C' : '#000000'};
							color: #ffffff;

							svg {
								color: ${theme.mode === 'light' ? '#FFFFFF' : '#FFFFFF'};
							}
						}
					}
				}
			}
		}
	`,
);
