import {
	DashboardEqualOutline,
	HandshakeHeartOutline,
	LinkOutline,
	FolderOutline,
	GiftOutline,
	TransactionOutline,
	CheckListOutline,
} from '@coinlocally/cly-core/icons';
import { useTranslation } from 'react-i18next';

function IbAsideConfig() {
	const { t } = useTranslation('aside');

	const aside_config = [
		{
			title: t('dashboard'),
			icon: <DashboardEqualOutline />,
			link: `ib-dashboard`,
		},
		{
			title: t('links'),
			icon: <LinkOutline />,
			link: `ib-dashboard/links`,
		},
		{
			title: t('acquisitions'),
			icon: <HandshakeHeartOutline />,
			link: `ib-dashboard/acquisitions`,
		},
		{
			title: t('client-list'),
			icon: <CheckListOutline />,
			link: `ib-dashboard/client-list`,
		},
		{
			title: t('accounts'),
			icon: <FolderOutline />,
			link: `ib-dashboard/accounts`,
		},
		{
			title: t('rewards'),
			icon: <GiftOutline />,
			link: `ib-dashboard/rewards`,
		},
		{
			title: t('transactions'),
			icon: <TransactionOutline />,
			link: `ib-dashboard/transactions`,
		},
	];

	return aside_config;
}

export default IbAsideConfig;
